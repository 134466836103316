// Generated by Framer (a1bdbac)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["TXciKT7d7"];

const variantClassNames = {TXciKT7d7: "framer-v-djo2je"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "TXciKT7d7", title: qYa8q8yQz = "Get started to free", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "TXciKT7d7", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-yGRNv", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-djo2je", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"TXciKT7d7"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(231, 231, 231)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgb(255, 249, 243)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} transition={transition}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter-Medium\", \"Inter\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.24px", "--framer-line-height": "20px", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Get started to free</motion.p></React.Fragment>} className={"framer-1c081ie"} data-framer-name={"Get started to free"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"SJuf97yuU"} style={{"--extracted-r6o4lv": "rgb(69, 94, 237)", "--framer-paragraph-spacing": "0px"}} text={qYa8q8yQz} transition={transition} verticalAlignment={"top"} withExternalLayout/><SVG className={"framer-vcy96k"} data-framer-name={"Navigation / Arrow-left"} fill={"rgba(0,0,0,1)"} intrinsicHeight={25} intrinsicWidth={25} layoutDependency={layoutDependency} layoutId={"Djn1LIbYk"} svg={"<svg width=\"25\" height=\"25\" viewBox=\"0 0 25 25\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M4.94922 11.8258V13.846H17.0704L11.5149 19.4016L12.9492 20.8359L20.9492 12.8359L12.9492 4.83594L11.5149 6.27028L17.0704 11.8258H4.94922Z\" fill=\"#455EED\"/>\n</svg>\n"} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-yGRNv [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-yGRNv * { box-sizing: border-box; }", ".framer-yGRNv .framer-uvnmsr { display: block; }", ".framer-yGRNv .framer-djo2je { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: visible; padding: 14px 24px 14px 24px; position: relative; width: min-content; }", ".framer-yGRNv .framer-1c081ie { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-yGRNv .framer-vcy96k { flex: none; height: 25px; position: relative; width: 25px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-yGRNv .framer-djo2je { gap: 0px; } .framer-yGRNv .framer-djo2je > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-yGRNv .framer-djo2je > :first-child { margin-left: 0px; } .framer-yGRNv .framer-djo2je > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 53
 * @framerIntrinsicWidth 218
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"qYa8q8yQz":"title"}
 */
const Framerek14hguoS: React.ComponentType<Props> = withCSS(Component, css, "framer-yGRNv") as typeof Component;
export default Framerek14hguoS;

Framerek14hguoS.displayName = "Button";

Framerek14hguoS.defaultProps = {height: 53, width: 218};

addPropertyControls(Framerek14hguoS, {qYa8q8yQz: {defaultValue: "Get started to free", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Framerek14hguoS, [])